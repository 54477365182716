

.form-control {
  margin-bottom: 1rem;
  padding: 15px;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.form-control input,
.form-control label {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea, 
.form-control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 100%;
 
}

.form-control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control-group {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.control-group .form-control {
  min-width: 15rem;
  flex: 1;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}

button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #33059e;
  border-color: #33059e;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}

