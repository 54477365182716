.checkoutcontainer{
    width: 70%;
    margin-left: 15%;
    padding: 10px;
  }
  

  .confirmation{
    padding: 5rem;
    background-color: rgb(230, 248, 253);
    float: center;
    clear: both;
    width: 100%;
  }


  .confirmation h2{
  font-size: 3rem;

  }