.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #8a2b06;
  margin: 10px;
}

.summary {
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-weight: bold;
  color: #8a2b06;
}

.amount {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 6px;
  color: #363636;
}

.actions {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .actions {
    flex-direction: row;
  }
}

.cart-item button {
  font: inherit;
  font-weight: bold;
  font-size: 1.25rem;
  background-color: #8a2b06;
  border: 1px solid #8a2b06;
  color: white;
  width: 3rem;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 1rem;
  margin: 0.25rem;
}

.cart-item button:hover,
.cart-item button:active {
  background-color: #a33206;
  color: white;
}