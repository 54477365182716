

.cart-items {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 30rem;
    overflow: auto;
  }
  
  .total {    
    justify-content: space-between;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  
  .actions {
    text-align: right;
  }
  
  .actions button {
    font: inherit;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #8a2b06;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    margin-left: 1rem;
  }
  
  .actions button:hover,
  .actions button:active {
    background-color: #5a1a01;
    border-color: #5a1a01;
    color: white;
  }
  
  .actions .button--alt {
    color: #8a2b06;
  }
  
  .actions button {
    background-color: #8a2b06;
    color: white;
  }


  .active{
    margin: 2em;
    color: white;
    font-weight: 500;
    text-decoration: none;
    size: 2em;
    background-color:  rgb(102,0,0);
    padding: 10px;
    border-radius: 5px;
    float: right;
    clear: both;
  }

  .closebutton{
   float: right;
   clear: both;
   width: 100%;
   padding-right: 1rem;
  
  }
  .closebutton button{
    float: right;
  background-color: #115381;
  color: white;
   border-radius: .5rem;
   margin-left: 10px;
  }

  .inputform{
    display: none;
  }
  