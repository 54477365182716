body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FFFFFF;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ssobtn{
  float: center;
  margin: 10px;
  padding: 20px;
}

.authbar{ 
  width: 70%;
  margin-left: 15%; 
  text-align: center;  
 
}

.authbar p{
  text-align: center;  
}
.Header_header__3-u2_ {
    top: 0;
    left: 0;
    width: 100%;
    height: 7rem;
    background-color: rgb(102,0,0);
    color: white; 
    align-items: center;        
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 10;
  }
   
  .Header_header__3-u2_ h1{
    padding-top: 2rem;
    color: white;
    font-size: 5ex;
    text-align: left;
    }
    
    .Header_header__3-u2_ img {
      margin-left: 33%;
        padding: 10px;
        float: left;
        clear: both;
        width: 100%;;
        max-width: 140px;
        height: auto;    
    }
 

    
.Modal_backdrop__2LYhw {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.Modal_modal__2vble {
  position: fixed;
  /*top: 20vh;*/
  top:5%;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  -webkit-animation: Modal_slide-down__2sTGq 300ms ease-out forwards;
          animation: Modal_slide-down__2sTGq 300ms ease-out forwards;
}

@media (min-width: 768px) {
  .Modal_modal__2vble {
    width: 70%;
    /*left: calc(50% - 20rem);*/
    left: 15%;
  }
}

@-webkit-keyframes Modal_slide-down__2sTGq {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Modal_slide-down__2sTGq {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}



.Cart_cart-items__1xzVK {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 30rem;
    overflow: auto;
  }
  
  .Cart_total__2vBoW {    
    justify-content: space-between;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  
  .Cart_actions__3mYA4 {
    text-align: right;
  }
  
  .Cart_actions__3mYA4 button {
    font: inherit;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #8a2b06;
    padding: 0.5rem 2rem;
    border-radius: 25px;
    margin-left: 1rem;
  }
  
  .Cart_actions__3mYA4 button:hover,
  .Cart_actions__3mYA4 button:active {
    background-color: #5a1a01;
    border-color: #5a1a01;
    color: white;
  }
  
  .Cart_actions__3mYA4 .Cart_button--alt__2eovx {
    color: #8a2b06;
  }
  
  .Cart_actions__3mYA4 button {
    background-color: #8a2b06;
    color: white;
  }


  .Cart_active__8J8e0{
    margin: 2em;
    color: white;
    font-weight: 500;
    text-decoration: none;
    size: 2em;
    background-color:  rgb(102,0,0);
    padding: 10px;
    border-radius: 5px;
    float: right;
    clear: both;
  }

  .Cart_closebutton__3Cxdp{
   float: right;
   clear: both;
   width: 100%;
   padding-right: 1rem;
  
  }
  .Cart_closebutton__3Cxdp button{
    float: right;
  background-color: #115381;
  color: white;
   border-radius: .5rem;
   margin-left: 10px;
  }

  .Cart_inputform__3Kb-Q{
    display: none;
  }
  
.CartItem_cart-item__25GkC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #8a2b06;
  margin: 10px;
}

.CartItem_summary__3ihDT {
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CartItem_price__2KcCi {
  font-weight: bold;
  color: #8a2b06;
}

.CartItem_amount__1n3XF {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 6px;
  color: #363636;
}

.CartItem_actions__3miiV {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .CartItem_actions__3miiV {
    flex-direction: row;
  }
}

.CartItem_cart-item__25GkC button {
  font: inherit;
  font-weight: bold;
  font-size: 1.25rem;
  background-color: #8a2b06;
  border: 1px solid #8a2b06;
  color: white;
  width: 3rem;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 1rem;
  margin: 0.25rem;
}

.CartItem_cart-item__25GkC button:hover,
.CartItem_cart-item__25GkC button:active {
  background-color: #a33206;
  color: white;
}
.Checkout_checkoutcontainer__2YyLz{
    width: 70%;
    margin-left: 15%;
    padding: 10px;
  }
  

  .Checkout_confirmation__1rjO0{
    padding: 5rem;
    background-color: rgb(230, 248, 253);
    float: center;
    clear: both;
    width: 100%;
  }


  .Checkout_confirmation__1rjO0 h2{
  font-size: 3rem;

  }


.form-control {
  margin-bottom: 1rem;
  padding: 15px;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.form-control input,
.form-control label {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea, 
.form-control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 100%;
 
}

.form-control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control-group {
  display: flex;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  flex-wrap: wrap;
}

.control-group .form-control {
  min-width: 15rem;
  flex: 1 1;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}

button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #33059e;
  border-color: #33059e;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}


.Orders_container__1G9iP{
    width: 90%;
    margin-left: 5%;
    margin-bottom: 3rem;
}

.Orders_navcontainer__2cyVU{
padding-top: 15px;
padding-left: 25px;

}
.Orders_active__Gv5Hn{
  min-width: 100px;
  background-color:  rgb(102,0,0);
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.Orders_active__Gv5Hn:hover{
  text-decoration: none;
  color: white;
}

.Orders_container__1G9iP h2{
text-align: center;
font-size: 2em;
}

.Orders_table__2pezJ{
border-radius: 15px;
border-color: #282c34;
border: 2px solid;
padding: 20px;
}

.Orders_orders__2FdXP {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 30rem;
    overflow: auto;
  }
.Orders_title__JkKW8{
padding: 15px;

}

  .Orders_title__JkKW8 a {    
    color: white;
    margin: 10px;
    text-align: right;
      font-weight: bold;
    text-decoration: none;
  }


  
  .Orders_container__1G9iP button {
    font-weight: medium;
    font-size: 1.25rem;
    background-color: #282c34;
    color: white;    
    width: auto;
    text-align: center;
    border-radius: 6px;   
    cursor: pointer;
    margin-left: 1rem;
    margin: 0.25rem;
    float: right;
  }


  .Order_order__1o20f {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #282c34;
    padding: 7px;   
  }


  .Order_button__2iKAz {
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #0c3b5d;
    color: white;
    padding: 5px;   
    align-items: center;
    border-radius: 15px;
    font-weight: bold;
    float: right;
    clear: both;
    text-decoration: none;
  }

  .Order_button__2iKAz:hover {
    background-color: #1f5a85;
    color: white;
    text-shadow: 1px 1px 1px white, 1px 1px 1px white;
  }


  .Order_active__zdPQD a{
    color: white;
    text-decoration: none;
  }
  

.OrderView_ordercont__QZrWX{
    width: 70%;
    margin-left: 15%;
    padding: 10px;
}

.OrderView_order__2w82v {
  width: 100%;
  float: left;
  clear: both;
  padding: 1.5rem;
  color: black;
  background-color: rgb(239, 239, 239);
  box-sizing: border-box;
  }

  .OrderView_orderdetail__xslGf{
   background-color: rgb(245, 245, 245);
    padding: .5rem;
  }

  .OrderView_ordertitle__3pCgT{
    background-color: #FFD700;
     padding: .15rem;
     font-size: 2rem;
   }

.OrderView_ordercont__QZrWX a {
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #0c3b5d;
    color: white;
    padding: 7px;   
    align-items: center;
    border-radius: 15px;
    font-weight: bold;
    float: right;
    clear: both;
    text-decoration: none;
  }


  .OrderView_order__2w82v h3 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0 0 0.25rem 0;
    text-align: center;
  }

  .OrderView_bold__3wAqd{
font-weight: bold;

  }
  
.itemcontainer{
  width: 70%;
  margin-left: 15%;
  padding: 10px;
}

.itemcontainer h3, h2{
text-align: center;
}

.item {
    /*display: flex;
    justify-content: space-between;*/
    float: left;
    margin: 1rem;
    padding: 10;    
    width: 95%;
    max-height: 70%;
  }

  .half {      
    max-width: 50%;
    min-width: 50%;
    max-height: 50%;    
    float: left; 
    padding-left: 1rem;    
    box-sizing: border-box;
  }

  .half img {      
    max-width: 90%;
    min-width: 90%;
   height:auto;   
   padding: 2rem;  
   box-sizing: border-box;
  }

  .name{
    min-height: 60px;
  }
  
  .item h3 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0 0 0.25rem 0;
    text-align: left;
  }
  
  .upc {
    font-style: italic;
    font-weight: bold;
    background-color: #FFD700;
    width: 100%;
  }

  .button {
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #0c3b5d;
    color: white;
    padding: 10px;   
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    float: right;
    clear: both;
    text-decoration: none;
  }
  
  .button:hover,
  .button:active {
    background-color: #115381;
  }
  
  .navlink{
    width: 100%;
    float: right;
    clear: both;
   margin-bottom: 10px;
  }

  .formcontrol {
    margin-bottom: 1rem;
    padding: 15px;
  }

  .formcontrol input,
  .formcontrol label {
    display: block;
  }

  .formcontrol label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .formcontrol input,
  .formcontrol textarea,
  .formcontrol select {
    font: inherit;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 10rem;
    max-width: 10rem;
   
  }
  
  .formcontrol input:focus {
    outline: none;
    border-color: #240370;
    background-color: #e0d4fd;
  }
  
  .itemadded{
   padding-top: 10%;

  }

  .addedbtns{
    margin-top: 20px;
    position: fixed;    
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
.CartButton_button__2XDr3 {
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #0c3b5d;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    font-weight: bold;
    margin: 10px;
    float: right;
  }
  
  .CartButton_button__2XDr3:hover,
  .CartButton_button__2XDr3:active {
    background-color: #115381;
  }
  
  .CartButton_icon__2DEon {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
  }
  
  .CartButton_badge__2alTD {
    background-color: #b94517;
    padding: 0.15rem .5rem;
    border-radius: 25px;
    margin-left: 1rem;
    font-weight: bold;
  }
  
  .CartButton_button__2XDr3:hover .CartButton_badge__2alTD,
  .CartButton_button__2XDr3:active .CartButton_badge__2alTD {
    background-color: #da4910;
  }
  
  .CartButton_bump__13gS- {
    -webkit-animation: CartButton_bump__13gS- 300ms ease-out;
            animation: CartButton_bump__13gS- 300ms ease-out;
  }
  
  @-webkit-keyframes CartButton_bump__13gS- {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    10% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    30% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    50% {
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes CartButton_bump__13gS- {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    10% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    30% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    50% {
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
