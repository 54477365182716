.itemcontainer{
  width: 70%;
  margin-left: 15%;
  padding: 10px;
}

.itemcontainer h3, h2{
text-align: center;
}

.item {
    /*display: flex;
    justify-content: space-between;*/
    float: left;
    margin: 1rem;
    padding: 10;    
    width: 95%;
    max-height: 70%;
  }

  .half {      
    max-width: 50%;
    min-width: 50%;
    max-height: 50%;    
    float: left; 
    padding-left: 1rem;    
    box-sizing: border-box;
  }

  .half img {      
    max-width: 90%;
    min-width: 90%;
   height:auto;   
   padding: 2rem;  
   box-sizing: border-box;
  }

  .name{
    min-height: 60px;
  }
  
  .item h3 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0 0 0.25rem 0;
    text-align: left;
  }
  
  .upc {
    font-style: italic;
    font-weight: bold;
    background-color: #FFD700;
    width: 100%;
  }

  .button {
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #0c3b5d;
    color: white;
    padding: 10px;   
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    float: right;
    clear: both;
    text-decoration: none;
  }
  
  .button:hover,
  .button:active {
    background-color: #115381;
  }
  
  .navlink{
    width: 100%;
    float: right;
    clear: both;
   margin-bottom: 10px;
  }

  .formcontrol {
    margin-bottom: 1rem;
    padding: 15px;
  }

  .formcontrol input,
  .formcontrol label {
    display: block;
  }

  .formcontrol label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .formcontrol input,
  .formcontrol textarea,
  .formcontrol select {
    font: inherit;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 10rem;
    max-width: 10rem;
   
  }
  
  .formcontrol input:focus {
    outline: none;
    border-color: #240370;
    background-color: #e0d4fd;
  }
  
  .itemadded{
   padding-top: 10%;

  }

  .addedbtns{
    margin-top: 20px;
    position: fixed;    
    left: 50%;
    transform: translateX(-50%);
  }