.container{
    width: 90%;
    margin-left: 5%;
    margin-bottom: 3rem;
}

.navcontainer{
padding-top: 15px;
padding-left: 25px;

}
.active{
  min-width: 100px;
  background-color:  rgb(102,0,0);
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.active:hover{
  text-decoration: none;
  color: white;
}

.container h2{
text-align: center;
font-size: 2em;
}

.table{
border-radius: 15px;
border-color: #282c34;
border: 2px solid;
padding: 20px;
}

.orders {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 30rem;
    overflow: auto;
  }
.title{
padding: 15px;

}

  .title a {    
    color: white;
    margin: 10px;
    text-align: right;
      font-weight: bold;
    text-decoration: none;
  }


  
  .container button {
    font-weight: medium;
    font-size: 1.25rem;
    background-color: #282c34;
    color: white;    
    width: auto;
    text-align: center;
    border-radius: 6px;   
    cursor: pointer;
    margin-left: 1rem;
    margin: 0.25rem;
    float: right;
  }