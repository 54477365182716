

  .order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #282c34;
    padding: 7px;   
  }


  .button {
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #0c3b5d;
    color: white;
    padding: 5px;   
    align-items: center;
    border-radius: 15px;
    font-weight: bold;
    float: right;
    clear: both;
    text-decoration: none;
  }

  .button:hover {
    background-color: #1f5a85;
    color: white;
    text-shadow: 1px 1px 1px white, 1px 1px 1px white;
  }


  .active a{
    color: white;
    text-decoration: none;
  }
  