
.ordercont{
    width: 70%;
    margin-left: 15%;
    padding: 10px;
}

.order {
  width: 100%;
  float: left;
  clear: both;
  padding: 1.5rem;
  color: black;
  background-color: rgb(239, 239, 239);
  box-sizing: border-box;
  }

  .orderdetail{
   background-color: rgb(245, 245, 245);
    padding: .5rem;
  }

  .ordertitle{
    background-color: #FFD700;
     padding: .15rem;
     font-size: 2rem;
   }

.ordercont a {
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #0c3b5d;
    color: white;
    padding: 7px;   
    align-items: center;
    border-radius: 15px;
    font-weight: bold;
    float: right;
    clear: both;
    text-decoration: none;
  }


  .order h3 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0 0 0.25rem 0;
    text-align: center;
  }

  .bold{
font-weight: bold;

  }
  