.header {
    top: 0;
    left: 0;
    width: 100%;
    height: 7rem;
    background-color: rgb(102,0,0);
    color: white; 
    align-items: center;        
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 10;
  }
   
  .header h1{
    padding-top: 2rem;
    color: white;
    font-size: 5ex;
    text-align: left;
    }
    
    .header img {
      margin-left: 33%;
        padding: 10px;
        float: left;
        clear: both;
        width: 100%;;
        max-width: 140px;
        height: auto;    
    }
 

    